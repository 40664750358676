import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placeTest,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeRedo,
  placeStartOver,
  print,
  plotFunction,
  setWidth,
  drawLine,
  placePlay,
  placePause,
  writeMediumText,
  drawPerpP,
  setColor,
  placeTapeVert,
  placeTapeHori
} from '../Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph =createSpace(-2.5,2.5,-2.5,2.5);
makeResponsive(graph);
graph.options.layer['image']=10;
graph.options.layer['angle']=8;
graph.options.layer['line']=10;
graph.options.layer['point']=12;
graph.options.layer['glider']=12;
//graph.options.layer['polygon']=10;
//createAxes(graph);
placeTitle(graph, 'Trigonometric Ratios', '(Drag Point P on the Unit Circle)');
//var n =placeInput(graph, 6.5, -3.5,'0');
/********************BEGINNING OF FILE************/
var center = drawPoint(graph, 0, 0);
setSize(graph, center, 0);
var rad = drawPoint(graph, 1, 0);
setSize(graph, rad, 0);
var circ = drawCircle(graph, center, rad);
var trace =drawMarker(graph, circ, 1, 1);
setSize(graph, trace, 3);
trace.setAttribute({color:'blue'});
labelIt(graph, trace, 'P(X, Y)');
var hypotenuse=drawSegment(graph, center, trace);
var horizontal =graph.create('segment', [[-2,0], [2,0]], {fixed: true, strokeWidth:1, strokeColor: 'grey'});
var vertical = graph.create('segment', [[0, -2], [0, 2]], {fixed: true, strokeWidth:1, strokeColor: 'grey'});
var oppositeP =drawPerpP(graph, horizontal, trace);
var adjacentP =drawPerpP(graph, vertical, trace);
var opposite = drawSegment(graph, trace, oppositeP);
setColor(graph, opposite, 'red');
var adjacent = drawSegment(graph, trace, adjacentP);
setColor(graph, adjacent, 'green');
/*******************************************/
var theta = graph.create('angle', [rad, center, trace],{name:()=> '&theta; = '+(JXG.Math.Geometry.rad(rad, center, trace)*180/Math.PI).toFixed(1) +'°' ,  visible: true, radius:0.25, strokeWidth:0, strokeColor:'black', fillColor:'red', label: {color: 'black' ,offset: [3,3], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
/*******************************************/
var pointOpp1 = graph.create('point', [1.25 , function(){return trace.Y()}], {visible: false, fixed: true});
var pointOpp2 = graph.create('point', [1.25 , 0], {visible: false, fixed: true});
placeTapeVert(graph, pointOpp1, pointOpp2, 'Y');
var pointAdj1 = graph.create('point', [0 , 1.25], {visible: false, fixed: true});
var pointAdj2 = graph.create('point', [function(){return trace.X()} , 1.25], {visible: false, fixed: true});
placeTapeHori(graph, pointAdj1, pointAdj2, 'X');
var sin =writeHTMLText(graph, -2, -1.25, function(){return 'sin &theta; = Y/R = '+ (trace.Y()).toFixed(3)});
sin.setAttribute({anchorX:'left'});
var cos = writeHTMLText(graph, -2, -1.65, function(){return 'cos &theta; = X/R = '+ (trace.X()).toFixed(3)});
cos.setAttribute({anchorX:'left'});
var tan = writeHTMLText(graph, -2, -2.05, function(){return'tan &theta; = Y/X = '+(trace.Y()/trace.X()).toFixed(3)});
tan.setAttribute({anchorX:'left'});
/******************END OF FILE ***************************/
}
}
export default Boxes;
